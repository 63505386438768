<template>
  <b-modal
    id="bill-printing-preferences"
    title="Configurações de Impressão"
    hide-footer
    size="xl"
    centered
    @hide="resetPreferences"
    @show="handleOnShow"
  >
  
  <div class="title">Selecione o formato e as informações que deseja incluir na impressão:</div>
    <div class="bills-print-config p-4">

      <b-col v-if="isBillToReceive">
        <b-form-group>
          <b-form-radio-group
            size="lg"
            v-model="selectedGroupByBillToReceive"
            :options="optionsGroupBy"
            stacked
            plain
          >
          </b-form-radio-group>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox-group
            size="lg"
            v-model="selectedViewingBillToReceive"
            :options="optionsViewingBillToReceive"
            stacked
          >
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col v-else>
        <b-form-group>
          <b-form-radio-group
            size="lg"
            v-model="selectedGroupByBillToPay"
            :options="optionsGroupBy"
            stacked
            plain
          >
          </b-form-radio-group>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox-group
            size="lg"
            v-model="selectedViewingBillToPay"
            :options="optionsViewingBillToPay"
            stacked
          >
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </div>

    <div class="modal-footer">
      <b-button
        variant="secondary"
        @click="$bvModal.hide('bill-printing-preferences')"
        >Voltar</b-button
      >
      <b-button variant="primary" @click="savePreferences">Solicitar impressão</b-button>
    </div>
  </b-modal>
</template>

<script>
import * as billPrintingPreferences from '@/utils/billPrintingPreferencesHelper'
export default {
  props: {
    clinicId: String,
    type: String,
    filters: Object
  },
  data() {
    return {
      optionsGroupBy: [
        { html: 'Agrupar contas por <b>período</b>', value: billPrintingPreferences.GROUP_BY_PERIOD },
        {
          html: 'Agrupar contas por dia de <b>criação da conta</b>',
          value: billPrintingPreferences.GROUP_BY_CREATED_AT
        }
      ],
      optionsViewingBillToReceive: [
        {
          html: 'Agrupar contas por <strong>método de recebimento</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_PAYMENT_METHOD_GROUP_BY
        },
        {
          html: 'Mostrar na tabela coluna de <strong>forma de recebimento</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_PAYMENT_METHOD
        },
        {
          html: 'Mostrar valor <strong>total de descontos</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_TOTAL_DISCOUNT
        },
        {
          html: 'Mostrar valor <strong>total líquido</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_TOTAL_LIQUID
        },
        {
          html: 'Mostrar na tabela coluna <strong>de conta bancária</strong>',
          value:    billPrintingPreferences.VIEWING_OPTIONS_BANK_ACCOUNT
        },
        {
          html: 'Mostrar na tabela coluna <strong>de taxa</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_RATE
        },
        {
          html: 'Mostrar na tabela coluna <strong>de parcela(s)</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_INSTALLMENT
        },
        {
          html: 'Mostrar detalhamento (quantidade e total líquido) por <strong>tipo de item</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_DETAIL
        }
      ],
      optionsViewingBillToPay: [
        {
          html: 'Agrupar contas por <strong>método de pagamento</strong>',
          value:  billPrintingPreferences.VIEWING_OPTIONS_PAYMENT_METHOD_GROUP_BY
        },
        {
          html: 'Mostrar na tabela coluna de <strong>forma de pagamento</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_PAYMENT_METHOD
        },
        {
          html: 'Mostrar valor <strong>total de descontos</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_TOTAL_DISCOUNT
        },
        {
          html: 'Mostrar valor <strong>total líquido</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_TOTAL_LIQUID
        },
        {
          html: 'Mostrar na tabela coluna <strong>de conta bancária</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_BANK_ACCOUNT
        },
        {
          html: 'Mostrar na tabela coluna <strong>de taxa</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_RATE
        },
        {
          html: 'Mostrar na tabela coluna <strong>de parcela(s)</strong>',
          value: billPrintingPreferences.VIEWING_OPTIONS_INSTALLMENT
        },
        {
          html: 'Mostrar detalhamento de quantidade, total de desconto e total líquido',
          value: billPrintingPreferences.VIEWING_OPTIONS_DETAIL
        }
      ],
      selectedGroupByBillToReceive: 'period',
      selectedViewingBillToReceive: [],
      selectedGroupByBillToPay: 'period',
      selectedViewingBillToPay: []
    }
  },
  computed: {
    isBillToReceive() {
      return this.type === billPrintingPreferences.BILL_TO_RECEIVE
    }
  },
  methods: {
    handleOnShow() {
      this.getBillPrintingPreferences()
    },
    async getBillPrintingPreferences() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getBillPrintingPreferences(
          this.clinicId
        )

        const billToReceive = data.find(item => item.type === 'bill_to_receive')
        const billToPay = data.find(item => item.type === 'bill_to_pay')
        
        if (billToReceive) {
          this.selectedGroupByBillToReceive = billToReceive.group_by
          this.selectedViewingBillToReceive = billToReceive.viewing_options
        }

        if (billToPay) {
          this.selectedGroupByBillToPay = billToPay.group_by
          this.selectedViewingBillToPay = billToPay.viewing_options
        }
      } catch (error) {
        console.log('error', error)
        this.$toast.error(
          'Erro ao buscar configurações de impressão, por favor tente novamente mais tarde ou entre em contato com o suporte.'
        )
      } finally {
        isLoading.hide()
      }
    },
    async savePreferences() {
      const isLoading = this.$loading.show()
      try {
        
        const data = [
          {
            type: 'bill_to_receive',
            group_by: this.selectedGroupByBillToReceive,
            viewing_options: this.selectedViewingBillToReceive
          },
          {
            type: 'bill_to_pay',
            group_by: this.selectedGroupByBillToPay,
            viewing_options: this.selectedViewingBillToPay
          }  
        ]
        const tabId = window.sessionStorage.getItem('tabId')
        
        const userId = JSON.parse(localStorage.getItem('user')).id;
        
        await this.api.printBillReports({
          clinic_id: this.clinicId,
          filters: this.filters,
          preferences: data,
          professional_id: userId,
          tabId
        })
        this.$bvModal.hide('bill-printing-preferences')
        this.$toast.success('PDF Personalizado solicitado com sucesso! Em instantes você receberá o arquivo.')
      } catch (error) {
        console.log('error', error)
        this.$toast.error(
          'Erro ao solicitar impressão, por favor tente novamente mais tarde ou entre em contato com o suporte.'
        ) 
      } finally {
        isLoading.hide()
      }
    },
    resetPreferences() {
      this.selectedGroupByBillToReceive = null
      this.selectedViewingBillToReceive = []
      this.selectedGroupByBillToPay = null
      this.selectedViewingBillToPay = []
    }
  }
}
</script>

<style lang="scss">
#bill-printing-preferences {

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }
    
    .title {
        font-family: Nunito Sans;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #525C7A;
    }
    
    .bills-print-config {
        border: 1px solid #D9DFF2;
        box-sizing: border-box;
        border-radius: 8px;
        text-align: left;
        margin-top: 20px;
        
        label {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-size: 14px;
            font-weight: 400;
            color: #525C7A !important;
        }
    }
}
</style>
